<template>
  <div>
      <b-card title="SMS Servisi">
        <b-row style="margin-top: 15px">
          <b-col xl="6">
            <b-form-textarea rows="3" max-rows="3" v-model="message" />
            <div style="margin: 2px; font-size: 0.9rem">
              Uzunluk: {{ message.length }} karakter
            </div>
          </b-col>
          <b-col xl="6">
            <b-form-group label="Telefon Numarası" label-for="phone">
              <b-input-group>
                <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                <b-form-input
                  id="phone"
                  v-model="phone"
                  placeholder=""
                  autocomplete="off"
                  :formatter="phoneFormatter"
                />
              </b-input-group>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-button class="ml-1 mb-1" variant="primary" @click="checkSms()">
                SMS Gönder
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        id="modal-approve-sms"
        ref="modal-approve-sms"
        hide-footer
        centered
        title="SMS'i Onayla"
      >
        <p>
          Mesajınız:<br />
          {{ message }}<br />
        </p>
        <p>
          Telefon:<br />
          {{ phone }}
        </p>

        <hr style="margin: 20px 10px" />
        <div>
          <b-button
            class="mr-1"
            style="float: right"
            variant="secondary"
            @click="$refs['modal-approve-sms'].hide()"
          >
            Kapat
          </b-button>
          <b-button
            class="mr-1"
            style="float: right"
            variant="primary"
            @click="sendSms()"
          >
            Onayla
          </b-button>
        </div>
      </b-modal>
    </div>
   

</template>

<script>
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
    BInputGroupPrepend,
  },
  data() {
    return {
      phone: "",
      message:
        "https://tusbuddy.com/anket-ekle web sayfamızdan TUS kadro anketi doldurabilirsiniz.",
    };
  },
  computed: {},
  methods: {

    async checkSms() {
      var phone = this.phone;
      if (!phone) {
        this.showError("Bir hata oluştu ve SMS gönderilemedi");
        return;
      }
      this.$refs["modal-approve-sms"].show();
    },

    async sendSms() {
      var phone = this.phone;
      this.$refs["modal-approve-sms"].hide();
      if (!phone.substring(0, 1) == "9" && !phone.substring(0, 1) == "0") {
        phone = "0" + phone;
      }
      var payload = {
        phone: phone,
        message: this.message,
      };
      var updated = await this.$store.dispatch("adminSms/sendSms", payload);
      if (updated) {
        this.showSuccess("SMS gönderildi.");
      } else {
        this.showError("Bir hata oluştu ve SMS gönderilemedi.");
      }
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: message,
          variant: "danger",
        },
      });
    },

    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "İşlem Başarılı!",
          icon: "BellIcon",
          text: message,
          variant: "success",
        },
      });
    },

    phoneFormatter: function (newValue) {
      newValue = newValue.trim();
      if (newValue.startsWith("5") == false) {
        newValue = newValue.substring(1, newValue.length);
      }
      var newPhoneValue = "";
      for (var i = 0; i < newValue.length && i < 13; i++) {
        if (
          newPhoneValue.length == 3 ||
          newPhoneValue.length == 7 ||
          newPhoneValue.length == 10
        ) {
          newPhoneValue = newPhoneValue + " ";
        }
        var character = newValue[i];
        if (this.isInt(character)) {
          newPhoneValue = newPhoneValue + character;
        }
      }
      this.phone = newPhoneValue;
      return this.phone;
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },
  },
  async created() {
    let moduleId = "sms_service";
    let moduleName = "SMS Gönderimi"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
